import { combineReducers } from "redux";
import AuthUserReducer from "./AuthUserReducer";
import ContactReducer from "./ContactReducer";
import AppSettingsReducer from "./AppSettingsReducer";
import UserSettingsReducer from "./UserSettingsReducer";
import MenuListReducer from "./MenuListReducer";
import AuthReducer from "./auth";
import FormInfoReducer from "./FormInfoReducer";
import UserMasterReducer from "./master/UserMasterReducer";
import DesignationMasterReducer from "./master/DesignationMasterReducer";
import DepartmentMasterReducer from "./master/DepartmentMasterReducer";
import CityMasterReducer from "./master/CityMasterReducer";
import WaDraftMasterReducer from "./master/WaDraftMasterReducer";
import LeadSourceReducer from "./master/LeadSourceMasterReducer";
import LeadFormReducer from "./master/LeadFormReducer";
import TemplateTriggerMasterReducer from "./master/TemplateTriggerMasterReducer";
import IndustryMasterReducer from "./master/IndustryMasterReducer";
import ProductMasterReducer from "./master/ProductMasterReducer";
import SettingMasterReducer from "./master/SettingMasterReducer";
import LeadsImportReducer from "./master/LeadsImportReducer";
import CallingHistoryReportReducer from "./reports/CallingHistoryReportReducer";
import DashboardReducer from "./dashboard/DashboardReducer";
import CallingPerformanceReducer from "./reports/CallingPerformanceReportReducer";
import LeadsReportReducer from "./reports/LeadsReportReducer";
import RemarkhMasterReducer from "./master/RemarkMasterReducer";
import WaDraftCategoryReducer from "./master/WaDraftCategoryMasterReducer";
import CompanyMasterReducer from "./master/CompanyMasterReducer";
import MetaFieldsMasterReducer from "./master/MetaFieldsMasterReducer";
import userCallLogReportReducer from "./reports/UserCallLogReducer";
import leadNoActionReducer from "./reports/LeadNoCallReducer";
import NADReducer from "./reports/NADReducer";
import BranchMasterReducer from "./master/BranchMasterReducer";
import shiftReducer from "./attendance/ShiftMasterReducer";
import weeklyOffReducer from "./attendance/WeeklyOffMasterReducer";
import holidayReducer from "./attendance/HolidayMasterReducer";
import AttendanceReportReducer from "./attendance/AttendanceReportReducer";
import MonthlyAttendanceReportReducer from "./attendance/MonthlyAttendanceReport";
import AttendanceSummaryReportReducer from "./attendance/AttendanceSummaryReportReducer"
import hrSettingReducer from "./master/HrSettingMasterReducer"
import weeklyOffRosterReducer from "./attendance/WeeklyOffRosterReducer"
import approvalMasterReducer from "./attendance/ApprovalMasterReducer"
import locationTracking from "./liveTracking/locationTracking";
import userTrackingData from "./liveTracking/userTracking";
import developerReducer from "./master/DeveloperMasterReducer";
import projectReducer from "./master/ProjectMasterReducer";
import associateReducer from "./master/AssociateMasterReducer";
import bankReducer from "./master/BankMasterReducer";
import bankBranchReducer from "./master/BankBranchMasterReducer";
import developerLeadsReportReducer from "./reports/DeveloperLeadsReportReducer";
import productCategoryReducer from "./master/ProductCategoryReducer";
import productNewMasterReducer from "./master/ProductNewMasterReducer";
import generateInvoicesReducer from "./master/GenerateInvoiceReducer";
import payoutReportReducer from "./reports/PayoutReportReducer";
import invoiceReportReducer from "./reports/InvoiceReportReducer";
import disbursementReportReducer from "./reports/DisbursementReportReducer";
import developerInvoiceReportReducer from "./reports/DeveloperInvoiceReportReducer";
import HDFCLeadsReducer from "./master/HDFCLeadsReducer";



const reducers = combineReducers({
  authUser: AuthUserReducer,
  settings: AppSettingsReducer,
  ContactReducer: ContactReducer,
  UserSettingsReducer: UserSettingsReducer,
  menuListReducer: MenuListReducer,
  authReducer: AuthReducer,
  formInfoReducer: FormInfoReducer,
  userMasterReducer: UserMasterReducer,
  designationMasterReducer: DesignationMasterReducer,
  departmentMasterReducer: DepartmentMasterReducer,
  cityMasterReducer: CityMasterReducer,
  waDraftMasterReducer: WaDraftMasterReducer,
  leadSourceReducer: LeadSourceReducer,
  leadFormReducer: LeadFormReducer,
  templateTriggerMasterReducer: TemplateTriggerMasterReducer,
  industryMasterReducer: IndustryMasterReducer,
  productMasterReducer: ProductMasterReducer,
  settingMasterReducer: SettingMasterReducer,
  leadsImportReducer: LeadsImportReducer,
  callingHistoryReportReducer: CallingHistoryReportReducer,
  dashboardReducer: DashboardReducer,
  callingPerformanceReducer: CallingPerformanceReducer,
  leadsReportReducer: LeadsReportReducer,
  remarkMasterReducer: RemarkhMasterReducer,
  waDraftCategoryReducer: WaDraftCategoryReducer,
  companyMasterReducer: CompanyMasterReducer,
  metaFieldsMasterReducer: MetaFieldsMasterReducer,
  userCallLogReportReducer: userCallLogReportReducer,
  leadNoActionReducer: leadNoActionReducer,
  NADReducer: NADReducer,
  branchMasterReducer: BranchMasterReducer,
  shiftMasterReducer: shiftReducer,
  weeklyOffReducer: weeklyOffReducer,
  HolidayReducer: holidayReducer,
  attendanceReportReducer: AttendanceReportReducer,
  monthlyAttendanceReportReducer: MonthlyAttendanceReportReducer,
  attendanceSummaryReportReducer: AttendanceSummaryReportReducer,
  hrSettingReducer: hrSettingReducer,
  weeklyOffRosterReducer: weeklyOffRosterReducer,
  approvalMasterReducer: approvalMasterReducer,
  locationTracking: locationTracking,
  userTrackingData: userTrackingData,
  developerReducer : developerReducer,
  projectReducer: projectReducer,
  productCategoryReducer:productCategoryReducer,
  productNewMasterReducer:productNewMasterReducer,
  associateReducer: associateReducer,
  bankReducer: bankReducer,
  bankBranchReducer: bankBranchReducer,
  developerLeadsReportReducer: developerLeadsReportReducer,
  generateInvoicesReducer: generateInvoicesReducer,
  payoutReportReducer: payoutReportReducer,
  invoiceReportReducer: invoiceReportReducer,
  disbursementReportReducer: disbursementReportReducer,
  developerInvoiceReportReducer: developerInvoiceReportReducer,
  HDFCLeadsReducer: HDFCLeadsReducer,
});



export default reducers;
