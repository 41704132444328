const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: 0,
  importResDataAuto: {},
  importResDataManual: {},
};

const HDFCLeadsReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case "GET_DATA_LEADS_REPORT":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };

    case "GET_HDFC_PAYOUT_REPORT":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "IMPORT_LEADS_MANUAL":
      return {
        ...state,
        importResDataManual: action.data,
      };

    default:
      return { ...state };
  }
};
export default HDFCLeadsReducer;
